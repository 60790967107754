import * as React from "react";
import styled from "styled-components";
import PageWrapper from "./page-wrapper";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Wrapper = styled(PageWrapper)`
  padding-top: 40px;
  padding-bottom: 0px;

  img {
    max-width: 100%;
  }

  .feature-image {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0 0 0 0.6px rgba(0, 0, 0, 0.1);

    .dark-mode & {
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
    }
  }

  .subtitle {
    color: var(--theme-color-text-dim);
  }
`;

const BlogPost = ({ title, image, html, excerpt }) => (
  <Wrapper>
    <article className="post">
      <h1>{title}</h1>
      <p className="subtitle">{excerpt}</p>
      {getImage(image) ? (
        <GatsbyImage
          image={getImage(image)}
          alt={title}
          className="feature-image"
        />
      ) : null}
      <section dangerouslySetInnerHTML={{ __html: html }} />
    </article>
  </Wrapper>
);

export default BlogPost;
