import { graphql } from "gatsby";
import * as React from "react";
import shuffle from "lodash/shuffle";
import styled from "styled-components";
import BlogPost from "../components/blog-post";
import WebsiteMeta from "../components/seo-website-meta";
import PageLayout from "../layouts/page-layout";
import BlogCard, { BlogCardGrid } from "../components/blog-card";
import PageWrapper from "../components/page-wrapper";

const MOBILE_BREAKPOINT = "screen and (min-width: 600px)";

const BlogSectionWrapper = styled.section`
  background: var(--color-feature-background);
  padding: 32px 0 48px;

  @media ${MOBILE_BREAKPOINT} {
    padding-top: 3.857143em;
    padding-bottom: 3.857143em;
  }

  .subtitle {
    opacity: 0.5;
    font-weight: 500;
    margin-bottom: 2.428571em;
  }
`;

const SectionTitle = styled.h3`
  font: var(--text-font-h3);
  font-weight: 700;
  margin: 0;
`;

const GhostPage = ({ data }) => {
  const page = data.ghostPage;

  if (!page) return null;

  const nodes = shuffle(data.allGhostPage.nodes).slice(0, 6);

  const postSlug = (post) => {
    let filtered = post.tags.filter((item) => item.slug !== "website");

    return filtered.length > 0 ? "/" + filtered[0].slug : "";
  };

  return (
    <PageLayout>
      <WebsiteMeta
        image={page.feature_image}
        title={page.title}
        description={page.excerpt}
        canonical={page.canonical_url}
        meta_title={page.meta_title}
        meta_description={page.meta_description}
        og_title={page.og_title}
        og_description={page.og_description}
        twitter_title={page.twitter_title}
        twitter_description={page.twitter_description}
        type="WebSite"
      />
      <BlogPost
        title={page.title}
        image={page.image}
        html={page.html}
        excerpt={page.excerpt}
      />
      <BlogSectionWrapper id="community">
        <PageWrapper>
          <SectionTitle>Learn More about Procure</SectionTitle>
          <p className="subtitle">
            Procure: Your All-in-One Purchasing Solution revolutionizes how
            businesses handle procurement, offering streamlined processes and
            efficient management of monthly expenses. Procure helps you fully
            comply with tax and regulatory requirements while optimizing
            operational efficiency for your business.
          </p>
          <BlogCardGrid>
            {nodes.map((post) => (
              <BlogCard post={post} key={post.slug} prefix={postSlug(post)} />
            ))}
          </BlogCardGrid>
        </PageWrapper>
      </BlogSectionWrapper>
    </PageLayout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    ghostPage(slug: { eq: $slug }) {
      title
      feature_image
      image: localImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      slug
      excerpt
      html
      canonical_url
      meta_title
      meta_description
      og_title
      og_description
      twitter_title
      twitter_description
    }
    allGhostPage(
      filter: {
        featured: { eq: true }
        tags: { elemMatch: { slug: { eq: "website" } } }
      }
    ) {
      nodes {
        slug
        image: feature_image
        title
        excerpt
        publishDate: published_at(fromNow: true)
        tags {
          name
          slug
        }
      }
    }
  }
`;

export default GhostPage;
